<template>
  <div style="margin-bottom: 3em">
    <!-- <Sidebar /> -->
    <HeaderPage />
    <div class="mt-15 ps-3 pb-3" height>
      <p class="ps-3 text_title">{{ $t("message.contactus") }}</p>
    </div>
    <div class="pt-5" style="background-color: #e1dddd">
      <FormulateForm
        class="px-4 pb-4"
        v-model="formValues"
        @submit="confirmForm"
      >
        <!-- <span for=""
          >{{ $t("message.title") }}
        </span>
        <FormulateInput
          class="mt-2"
          type="select"
          name="messageTitle"
          :options="optionsMessageTitle"
        /> -->
        <span for="">{{ $t("message.order-number") }}</span>
        <FormulateInput type="text" name="orderNumber" />

        <span for=""
          >{{ $t("message.lastname") }} - {{ $t("message.name") }}
          <span class="pa-1 white--text label-required">{{
            $t("message.required")
          }}</span></span
        >
        <div class="double-wide mt-2">
          <FormulateInput type="text" name="lastName" validation="required" />
          <FormulateInput type="text" name="firstName" validation="required" />
        </div>
        <span class="" for=""
          >{{ $t("message.furigana") }}
          <span class="pa-1 white--text label-required">{{
            $t("message.required")
          }}</span></span
        >
        <div class="double-wide mt-2">
          <FormulateInput type="text" name="furigana_1" validation="required" />
          <FormulateInput type="text" name="furigana_2" validation="required" />
        </div>
        <span for=""
          >{{ $t("message.label-phone") }}
          <span class="pa-1 white--text label-required">{{
            $t("message.required")
          }}</span></span
        >
        <FormulateInput
          class="mt-2"
          type="number"
          validation="required|min:9,length|max:13,length"
          name="phone"
          :options="optionsMessageTitle"
        />
        <span for="">{{ $t("message.called") }}</span>
        <FormulateInput
          v-model="radioVal"
          validation="required"
          name="isCall"
          type="radio"
          :options="optionsCall"
        />
        <span for=""
          >{{ $t("message.email") }}
          <span class="pa-1 white--text label-required">{{
            $t("message.required")
          }}</span></span
        >
        <FormulateInput
          class="mt-2"
          type="email"
          name="email"
          validation="required"
        />
        <p class="desc-email mb-5">{{ $t("message.desc-email") }}</p>

        <span for=""
          >{{ $t("message.re-enter-email") }}
          <span class="pa-1 white--text label-required mt-2">
            {{ $t("message.required") }}</span
          ></span
        >
        <FormulateInput
          class="mt-2"
          type="email"
          name="email_confirm"
          validation="required|confirm"
          validation-name="Confirmation"
        />

        <span for=""
          >{{ $t("message.content") }}
          <span class="pa-1 white--text label-required">
            {{ $t("message.required") }}</span
          ></span
        >
        <FormulateInput
          class="mt-2"
          type="textarea"
          name="content"
          validation="required"
        />
        <FormulateInput class="text-center" type="submit" :label="proceedBtn" />
      </FormulateForm>
    </div>

    <div class="my-5 py-5">
      <div class="text-center d-flex justify-center">
        <v-btn @click.prevent="$router.push('/')" depressed class="border-btn">
          <span class="pink--text" style="text-transform: none;">
            {{ $t("message.back-to-top") }}
          </span>
        </v-btn>
      </div>
    </div>
    <div style="margin-bottom: 8rem">
      <CategoryFooter />
    </div>
    <Sidebar />
  </div>
</template>

<script>
import Sidebar from "../../components/developmentv2/Sidebar.vue";
import HeaderPage from '../../components/developmentv2/HeaderPage.vue'
import CategoryFooter from "../../components/CategoryFooter.vue";
export default {
  name: "ContactUs",
  components: {
    Sidebar,
    CategoryFooter,
    HeaderPage
  },
  data() {
    return {
      textareaVal: "",
      radioVal: 1,
      formValues: {
        messageTitle: "",
        orderNumber: "",
        email: "",
        email_confirm: "",
        content: "",
        furigana_1: "",
        furigana_2: "",
        isCall: "",
        lastName: "",
        firstName: "",
        phone: ""
      },
    };
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  created() {
    this.dipsatchMessageTitle();
    this.getValueContact();
  },
  computed: {
    proceedBtn() {
      return this.$t("message.proceed");
    },
    placeholder() {
      return this.$t("message.select-an-option");
    },
    optionsCall() {
      return [
        { value: 1, label: this.$t("message.called-no") },
        { value: 2, label: this.$t("message.called-yes") },
      ];
    },
    optionsMessageTitle() {
      return this.$store.state.about_module.messageTitle;
    },
  },
  methods: {
    async dipsatchMessageTitle() {
      await this.$store.dispatch("about_module/messageTitle");
    },
    async confirmForm(data) {
      await this.$recaptchaLoaded();
      data.recaptchatoken = await this.$recaptcha('login');
      const contactString = JSON.stringify(data);
      localStorage.setItem("cntc", contactString);

      this.$router.push("/confirmation");
      // const contactString = JSON.stringify(data);
      // localStorage.setItem("cntc", contactString);

      this.$gtag.event("contact-us-click", {
        event_category: "Contact Us",
        event_label: "Contact Us",
      });
      // this.$store.dispatch("about_module/submitContact", data);
    },
    getValueContact() {
      if (localStorage.cntc) {
        let parsedCntc = JSON.parse(localStorage.getItem("cntc"))
        this.formValues.messageTitle = parsedCntc.messageTitle;
        this.formValues.email = parsedCntc.email;
        this.formValues.email_confirm = parsedCntc.email_confirm;
        this.formValues.firstName = parsedCntc.firstName;
        this.formValues.lastName = parsedCntc.lastName;
        this.formValues.furigana_1 = parsedCntc.furigana_1;
        this.formValues.furigana_2 = parsedCntc.furigana_2;
        this.formValues.isCall = parsedCntc.isCall;
        this.formValues.orderNumber = parsedCntc.orderNumber;
        this.formValues.phone = parsedCntc.phone;
        this.formValues.content = parsedCntc.content;
      } else {
        this.formValues.messageTitle = ""
        this.formValues.email = ""
        this.formValues.email_confirm = ""
        this.formValues.firstName = ""
        this.formValues.lastName = ""
        this.formValues.furigana_1 = ""
        this.formValues.furigana_2 = ""
        this.formValues.isCall = ""
        this.formValues.orderNumber = ""
        this.formValues.phone = ""
        this.formValues.content = ""
      }
    },
  },
};
</script>

<style scoped>
.desc-email {
  line-height: 20px;
  font-size: 10px;
}
.desc-email::after {
  content: "\a";
  white-space: pre;
  margin-bottom: 1em;
}
.label-required {
  background-color: #ff0090;
  border-radius: 5px;
}
.spacing_content {
  /* padding-right: 1em;
  padding-left: 1em; */
  margin-right: 1em;
  margin-left: 1em;
}
.border-btn {
  /* border-color: #707070 !important; */
  border: 2px solid #707070 !important;
}
.spacing {
  padding: 0px !important;
  margin: 0px !important;
}
.text_title {
  border-left: 10px solid #ff0090;
  font-size: 18px;
}
.formulate-input-element--group {
  display: flex;
}
.theme--light.v-divider {
  border-color: #ff0090 !important;
}
@media (min-width: 360px) {
  .double-wide {
    display: flex;
  }
  .double-wide .formulate-input {
    flex-grow: 1;
    width: calc(50% - 0.5em);
  }
  .double-wide .formulate-input:first-child {
    margin-right: 0.5em;
  }
  .double-wide .formulate-input:last-child {
    margin-left: 0.5em;
  }
}
</style>
